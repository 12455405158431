<template>
  <div id="app">
    <div class="container">
      <div class="left">
        <div class="search">
          <el-input
            placeholder="输入关键字进行搜索..."
            clearable
            prefix-icon="el-icon-search"
            v-model="filterText"
            style="margin: 0 10px 5px 0; width: 100%"
          >
          </el-input>
          <div>
            <i class="iconfont icon-iotech24gf-tags3"></i>
            <span> 设备列表</span>
            <!-- <span style="float:right">
              <i class="iconfont icon-iotech24gf-tags3" @click="toggle"></i>
            </span> -->
          </div>
        </div>
        <!-- 公司列表 -->
        <div class="info-list">
          <el-tree
            :data="deviceInfoList"
            :props="defaultProps"
            highlight-current
            default-expand-all
            node-key="id"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            @node-click="getPickCompant"
            ref="tree"
          >
            <span slot-scope="{ node, data }">
              <span :title="node.label">{{
                node.label ? node.label : data.deviceId
              }}</span>
            </span>
          </el-tree>
        </div>
      </div>

      <dv-border-box-4 class="content" :color="['#135b85', '#0ce3eb']">
        <div class="title">
          <span @click="toggleTags('left')">
            <!-- {{ deviceInfo.deviceName ? deviceInfo.deviceName : deviceAttribute.id }} -->
            {{ titleName == "" ? deviceInfo.deviceName : titleName }}
          </span>
         
        </div>
        <dv-decoration-1
          style="width: 360px; height: 10px"
          :color="['#0ce3eb']"
        />

        <!-- 设备信息和数据统计 -->
        <div v-if="flag">
          <div class="banner">
            <el-col :span="6" class="rows">
              <el-date-picker
                v-model="dataTime"
                popper-class="pickerclass"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :clearable="false"
                @change="pickTime"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="9" class="rows">
              <!-- <el-radio v-model="radio" label="Daily" border style="color:#fff;border:2px solid #008c8c;"
                @input="checkRadio(radio)">日用 水（电）量</el-radio>
              <el-radio v-model="radio" label="Monthly" border style="color:#fff;border:2px solid #008c8c;"
                @input="checkRadio(radio)">月用 水（电）量</el-radio> -->
              <el-button
                type="primary"
                plain
                style="margin-left: 10px"
                @click="checkWater('DXwaterMeter')"
                >查 询</el-button
              >
              <!-- <el-button type="primary" plain style="margin-left:10px"
                @click="checkWater('DXwaterMeter')">查询用水量</el-button>
              <el-button type="primary" plain style="margin-left:10px"
                @click="checkElect('DXElectricMeter')">查询用电量</el-button> -->
            </el-col>
          </div>
          <div class="device-info">
            <div class="titles">
              <i class="iconfont icon-iotech24gf-tags3"></i>
              <span> 设备信息</span>
            </div>
            <dv-decoration-3
              style="width: 250px; height: 10px; margin: 10px 0"
            />
            <div class="infolist">
              <div class="item">
                IMEI：{{ deviceAttribute.device[0].deviceId }}
              </div>
              <div
                class="item"
                v-for="(item, index) in deviceAttribute.device"
                :key="index"
              >
                {{ item.proName }}：{{ item.value }}
              </div>

              <div class="item" v-for="item in changedDeviceInfoList">
                {{ item.name == "用水量" ? "水表读数" : item.name }}：{{
                  item.value
                }}
              </div>
            </div>
          </div>
          <div class="device-rate">
            <div class="titles">
              <i class="iconfont icon-iotech24gf-tags3"></i>
              <span> 数据统计</span>
            </div>
            <dv-decoration-3
              style="width: 250px; height: 10px; margin: 10px 0"
            />
            <div class="line">
              <div class="total">
                <div class="title">总{{ option }}量：</div>
                <el-tooltip class="item" effect="dark" content="本数据来源于水表读数相减" placement="bottom">
                  <div class="num">
                  {{ (acquisition - 0).toFixed(2) }}
                </div>
                </el-tooltip>
                <div class="unity">{{ units }}</div>
              </div>
              <div class="lefts">
                <div class="title">日{{ option }}量统计：</div>
                <div class="rows">
                  <div>
                    当日 {{ option }} 量：（ {{ units }} ）
                    <span>{{ dailyData ? dailyData.toFixed(2) : 0 }}</span>
                  </div>
                  <div>
                    昨日 {{ option }} 量：（ {{ units }} ）
                    <span>{{
                      yesterdayData ? yesterdayData.toFixed(2) : 0
                    }}</span>
                  </div>
                  <div>
                    日环比：
                    <!-- <span>{{yesterdayData?(dailyData/yesterdayData*100).toFixed(2):0}} %</span> -->
                     <span
                      >{{
                        yesterdayData
                          ? dailyData >= yesterdayData && dailyData != 0
                            ? (
                                ((dailyData - yesterdayData) / yesterdayData) *
                                100
                              ).toFixed(2)
                            : -(
                                ((yesterdayData - dailyData) / yesterdayData) *
                                100
                              ).toFixed(2)
                          : 0
                      }}
                      %</span
                    >
                  </div>
                </div>
              </div>
              <div class="lefts">
                <div class="title">月{{ option }}量统计：</div>
                <div class="rows">
                  <div>
                    当月 {{ option }} 量：（
                    {{ units }}
                    ）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ monthDate }}
                    <span>{{ monthData ? monthData.toFixed(2) : 0 }}</span>
                  </div>
                  <div>
                    上月 {{ option }} 量：（
                    {{ units }}
                    ）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ lastMonthDate }}
                    <span>{{ monthlyData ? monthlyData.toFixed(2) : 0 }} </span>
                  </div>
                  <div>
                    月环比：
                    <!-- <span>{{monthlyData?(monthData/monthlyData*100).toFixed(2):0}} %</span> -->
                    <span
                      >{{
                        monthlyData
                          ? monthData >= monthlyData && monthData != 0
                            ? (
                                ((monthData - monthlyData) / monthlyData) *
                                100
                              ).toFixed(2)
                            : -(
                                ((monthlyData - monthData) / monthlyData) *
                                100
                              ).toFixed(2)
                          : 0
                      }}
                      %</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 数据分析 -->
        <div class="device-statistic" v-if="flag">
          <div class="titles">
            <i class="iconfont icon-iotech24gf-tags3"></i>
            <span> 数据分析</span>
          </div>
          <dv-decoration-3 style="width: 250px; height: 10px; margin: 10px 0" />
          <DeviceStatistic
            :deviceDataInfo="deviceDataInfo"
            :deviceName="deviceInfo.deviceName"
          ></DeviceStatistic>
        </div>

        <!-- 历史记录 -->
        
      </dv-border-box-4>
    </div>
  </div>
</template>

<script>
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";
import DeviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import DeviceStatistic from "@/components/WaterAndElectricity/DeviceStatistic.vue";
import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

export default {
  components: {
    DeviceStatistic,
  },
  props: ["propDeviceInfo"],
  data() {
    return {
      titleName: "",
      // 上月水表日期
      monthDate: "",
      lastMonthDate: "",
      flag: false,
      radio: "Daily",

      //表格数据
      historyData: [],
      //一天每半小时时间
      timeArrays: [],

      // 时间选择器，默认最近7天
      dataTime: [],

      // 历史记录 时间
      dataTime_history: [
        this.$moment().add(-29, "d").format("YYYY-MM-DD HH:mm:ss"),
        this.$moment().add(0, "d").format("YYYY-MM-DD HH:mm:ss"),
      ],

      deviceTypeId: "DXwaterMeter",

      defaultProps: {
        children: "deviceList", // 子公司节点
        label: "deviceName", // 公司名
      },

      deviceInfo: {},

      // 分级设备集合
      deviceInfoList: [],

      // 查找设备过滤
      filterText: "",
      paramsObj: {},

      // 设备属性
      deviceAttribute: [],

      // 设备数据信息
      deviceDataInfo: [],

      // 总用量
      acquisition: 0,

      // 单位切换
      units: "m3",
      option: "用水",

      dailyData: 0,
      yesterdayData: 0,
      monthData: 0,
      monthlyData: 0,

      // 设备动态属性
      deviceData: [],
      //设备信息显示集合
      changedDeviceInfoList: [],
      timer: null,
      deviceId: "861821066549723",

      deviceMap: new Map(), //用来存储每个设备对应的索引
      staticValueList: [], //用来存储每个设备对应的数据
    };
  },
  created() {
    // 设定默认日期
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let month1 = date.getMonth() + 1;
    let day = "20";
    let time = "00:00:00";
    if (month < 10) {
      month = "0" + month;
    }
    if (month1 < 10) {
      month1 = "0" + month1;
    }
    let startTime = year + "-" + month + "-" + day + " " + time;
    let endTime = year + "-" + month1 + "-" + day + " " + time;
    this.dataTime[0] = startTime;
    this.dataTime[1] = endTime;

    this.getDeviceInfo(this.deviceTypeId);
    //获取一天内每半小时时间
    this.timeArrays = this.getDateArray();
  },
  mounted() {
    this.filterText = "";
    if (
      this.propDeviceInfo.deviceName !== "" ||
      this.propDeviceInfo.deviceName !== null ||
      this.propDeviceInfo.deviceName !== undefined
    ) {
      this.filterText = this.propDeviceInfo.deviceName;
    }
    console.log("===========================");
    this.getAcquisition()

  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    deviceDataInfo(val) {
      if (val) {
        this.flag = true;
        this.deviceDataInfo = val;
      }
    },
  },
  methods: {
    //改变设备的状态
    async changeDeviceStatus(device, deviceTypeId) {
      //如果是智慧用电相关设备，对数据单独处理
      if (deviceTypeId == "smartElectricity") {
      } else if (deviceTypeId == "IntelligentFireControlTerminal") {
        // 《总线物联网4G中继器》设备数据处理
        const { data: res } =
          await DeviceInfoManagement.getDeviceInfoValueByDeviceId(device);
        this.deviceValueInfoList = res.data.list;
        this.busEquipmentMethod();
      } else {
        const { data: res } =
          await DeviceInfoManagement.getDeviceInfoByDeviceId(device, 1, 1);
        this.deviceValueInfoList = [];
        let lastDataTime = res.data.deviceDynamicVo["lastDataTime"];
        let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"];
        let isOnline = true;
        if (lastDataTime == null || createDeviceTime > lastDataTime) {
          isOnline = false;
        }
        for (const i in res.data.deviceDynamicVo["nameList"]) {
          let obj = {};
          obj.id = res.data.deviceDynamicVo["nameList"][i];
          obj.value = res.data.deviceDynamicVo["valueList"][i];
          this.deviceValueInfoList.push(obj);
        }
        //其它类型设备走下面正常处理
        let deviceStatus = "";
        for (const i in this.deviceValueInfoList) {
          if (
            (this.deviceValueInfoList[i]["id"].indexOf("状态") != -1 ||
              this.deviceValueInfoList[i]["id"] == "报警类型") &&
            this.deviceValueInfoList[i]["value"] != "" &&
            ((this.deviceValueInfoList[i]["value"] == "正常" &&
              deviceStatus == "") ||
              this.deviceValueInfoList[i]["value"] != "正常") &&
            this.deviceValueInfoList[i]["value"] != null &&
            this.deviceValueInfoList[i]["value"] != undefined
          ) {
            if (
              (this.deviceValueInfoList[i]["value"].indexOf("正常") != -1 &&
                this.deviceValueInfoList[i]["value"].indexOf("故障") == -1 &&
                this.deviceValueInfoList[i]["value"].indexOf("警") == -1) ||
              this.deviceValueInfoList[i]["value"].indexOf("无") != -1 ||
              this.deviceValueInfoList[i]["value"].indexOf("解除") != -1
            ) {
              deviceStatus = "正常";
            } else {
              deviceStatus = this.deviceValueInfoList[i]["value"];
            }
          }
        }
        let isLost = false; //判断电信设备是否离线等
        if (deviceTypeId.indexOf("DX") != -1) {
          const { data: ress } =
            await DeviceInfoManagement.queryAEPDeviceStatus(
              deviceTypeId,
              device
            );
          if (ress.data.status == "离线") {
            deviceStatus = "离线";
            isLost = true;
          } else if (ress.data.status == "已注册") {
            deviceStatus = "未激活";
            isLost = true;
          } else if (ress.data.status == "已注销") {
            deviceStatus = "已注销";
            isLost = true;
          }
        }
        if (isLost == false) {
          if (deviceStatus != "" && isOnline == true) {
          } else if (deviceStatus == "" && isOnline == true) {
            deviceStatus = "正常";
          } else {
            if (
              device.indexOf("IOE") != -1 ||
              device.indexOf("_") != -1 ||
              deviceTypeId == "DXHippoAcoustoOptic" ||
              deviceTypeId == "numPressureGauge"
            ) {
              if (deviceStatus == "") {
                deviceStatus = "正常";
              }
            } else {
              deviceStatus = "未服务";
              isLost = true;
            }
          }
        }
        for (const i in this.changedDeviceInfoList) {
          if (this.changedDeviceInfoList[i].name.indexOf("状态") != -1) {
            this.changedDeviceInfoList[i].value = deviceStatus;
          }
        }
      }
    },
    //设备状态
    async getDeviceValue(device, deviceTypeId) {
      this.changedDeviceInfoList = [];
      //如果是智慧用电相关设备，对数据单独处理
      if (deviceTypeId == "smartElectricity") {
        const { data: res } =
          await DeviceInfoManagement.getDeviceInfoValueByDeviceId(device);
        this.deviceValueInfoList = res.data.list;
        // 智慧用电数据处理方法
        this.wisdomElectricityInfo();

        // 《总线物联网4G中继器》设备数据处理
      } else if (deviceTypeId == "IntelligentFireControlTerminal") {
        const { data: res } =
          await DeviceInfoManagement.getDeviceInfoValueByDeviceId(device);
        this.deviceValueInfoList = res.data.list;
        this.busEquipmentMethod();
      } else {
        const { data: res } =
          await DeviceInfoManagement.getDeviceInfoByDeviceId(device, 1, 1);
        this.deviceValueInfoList = [];
        this.updateTime = res.data.deviceDynamicVo.lastDataTime;
        let lastDataTime = res.data.deviceDynamicVo["lastDataTime"];
        let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"];
        let isOnline = true;
        if (lastDataTime == null || createDeviceTime > lastDataTime) {
          isOnline = false;
        }
        for (const i in res.data.deviceDynamicVo["nameList"]) {
          let obj = {};
          obj.id = res.data.deviceDynamicVo["nameList"][i];
          obj.value = res.data.deviceDynamicVo["valueList"][i];
          this.deviceValueInfoList.push(obj);
        }
        //其它类型设备走下面正常处理
        let typeMap = new Map([
          ["井盖", { name: [], value: [] }],
          ["压力", { name: [], value: [] }],
          ["电池", { name: [], value: [] }],
          ["水位", { name: [], value: [] }],
        ]);
        let typeList = ["井盖", "压力", "电池", "水位"];
        this.showDeviceInfoList = []; //清空数据
        let deviceStatus = "";
        let nameList = [];
        let valueList = [];
        let obj = {};
        for (const i in this.deviceValueInfoList) {
          nameList = [];
          valueList = [];
          if (typeMap.has(this.deviceValueInfoList[i]["id"].substring(0, 2))) {
            typeMap
              .get(this.deviceValueInfoList[i]["id"].substring(0, 2))
              ["name"].push(this.deviceValueInfoList[i]["id"]);
            typeMap
              .get(this.deviceValueInfoList[i]["id"].substring(0, 2))
              ["value"].push(this.deviceValueInfoList[i]["value"]);
          } else if (
            (this.deviceValueInfoList[i]["id"].indexOf("状态") != -1 ||
              this.deviceValueInfoList[i]["id"] == "报警类型") &&
            this.deviceValueInfoList[i]["value"] != "" &&
            ((this.deviceValueInfoList[i]["value"] == "正常" &&
              deviceStatus == "") ||
              this.deviceValueInfoList[i]["value"] != "正常") &&
            this.deviceValueInfoList[i]["value"] != null &&
            this.deviceValueInfoList[i]["value"] != undefined
          ) {
            if (
              (this.deviceValueInfoList[i]["value"].indexOf("正常") != -1 &&
                this.deviceValueInfoList[i]["value"].indexOf("故障") == -1 &&
                this.deviceValueInfoList[i]["value"].indexOf("警") == -1) ||
              this.deviceValueInfoList[i]["value"].indexOf("无") != -1 ||
              this.deviceValueInfoList[i]["value"].indexOf("解除") != -1
            ) {
              deviceStatus = "正常";
            } else {
              deviceStatus = this.deviceValueInfoList[i]["value"];
            }
          } else if (
            (this.deviceValueInfoList[i]["id"].indexOf("值") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("压力") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("电流") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("电压") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("角度") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("电量") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("功率") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("用水") != -1 ||
              this.deviceValueInfoList[i]["id"].indexOf("用电") != -1) &&
            this.deviceValueInfoList[i]["id"].indexOf("状态") == -1 &&
            this.deviceValueInfoList[i]["id"].indexOf("记录") == -1 &&
            this.deviceValueInfoList[i]["id"].indexOf("时间") == -1
          ) {
            nameList.push(this.deviceValueInfoList[i]["id"]);
            if (
              this.deviceValueInfoList[i]["value"] == "" ||
              this.deviceValueInfoList[i]["value"] == null ||
              this.deviceValueInfoList[i]["value"] == undefined
            ) {
              valueList.push("暂无");
            } else {
              valueList.push(this.deviceValueInfoList[i]["value"]);
            }
            obj["nameList"] = nameList;
            obj["valueList"] = valueList;
            this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)));
          }
        }
        nameList = [];
        valueList = [];
        let isLost = false; //判断电信设备是否离线等
        if (deviceTypeId.indexOf("DX") != -1) {
          const { data: ress } =
            await DeviceInfoManagement.queryAEPDeviceStatus(
              deviceTypeId,
              device
            );
          if (ress.data.status == "离线") {
            nameList.push("设备状态");
            valueList.push("离线");
            isLost = true;
          } else if (ress.data.status == "已注册") {
            nameList.push("设备状态");
            valueList.push("未激活");
            isLost = true;
          } else if (ress.data.status == "已注销") {
            nameList.push("设备状态");
            valueList.push("已注销");
            isLost = true;
          }
        }
        if (isLost == false) {
          if (deviceStatus != "" && isOnline == true) {
            nameList.push("设备状态");
            valueList.push(deviceStatus);
          } else if (deviceStatus == "" && isOnline == true) {
            nameList.push("设备状态");
            valueList.push("正常");
          } else {
            if (
              device.indexOf("IOE") != -1 ||
              device.indexOf("_") != -1 ||
              deviceTypeId == "DXHippoAcoustoOptic" ||
              deviceTypeId == "numPressureGauge"
            ) {
              if (deviceStatus != "") {
                nameList.push("设备状态");
                valueList.push(deviceStatus);
              } else {
                nameList.push("设备状态");
                valueList.push("正常");
              }
            } else {
              nameList.push("设备状态");
              valueList.push("未服务");
              isLost = true;
            }
          }
        }
        obj["nameList"] = nameList;
        obj["valueList"] = valueList;
        this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)));
        let mapStatus = ""; //用于特殊类型状态显示
        for (const i in typeList) {
          obj = {};
          if (typeMap.get(typeList[i]).name.length != 0) {
            let status = "";
            nameList = [];
            valueList = [];
            for (const ii in typeMap.get(typeList[i]).name) {
              if (
                typeMap.get(typeList[i]).name[ii].indexOf("状态") != -1 &&
                typeMap.get(typeList[i]).value[ii] != "" &&
                ((typeMap.get(typeList[i]).value[ii] == "正常" &&
                  status == "") ||
                  typeMap.get(typeList[i]).value[ii] != "正常") &&
                typeMap.get(typeList[i]).value[ii] != null &&
                typeMap.get(typeList[i]).value[ii] != undefined
              ) {
                status = typeMap.get(typeList[i]).value[ii];
                if (status != null && status != "" && status != "正常") {
                  mapStatus = status;
                }
              } else if (
                (typeMap.get(typeList[i]).name[ii].indexOf("值") != -1 ||
                  typeMap.get(typeList[i]).name[ii].indexOf("压力") != -1 ||
                  typeMap.get(typeList[i]).name[ii].indexOf("电流") != -1 ||
                  typeMap.get(typeList[i]).name[ii].indexOf("电压") != -1 ||
                  typeMap.get(typeList[i]).name[ii].indexOf("角度") != -1 ||
                  typeMap.get(typeList[i]).name[ii].indexOf("电量") != -1) &&
                typeMap.get(typeList[i]).name[ii].indexOf("状态") == -1 &&
                typeMap.get(typeList[i]).name[ii].indexOf("记录") == -1 &&
                typeMap.get(typeList[i]).name[ii].indexOf("时间") == -1
              ) {
                nameList.push(typeMap.get(typeList[i]).name[ii]);
                if (
                  typeMap.get(typeList[i]).value[ii] == "" ||
                  typeMap.get(typeList[i]).value[ii] == null ||
                  typeMap.get(typeList[i]).value[ii] == undefined
                ) {
                  valueList.push("暂无");
                } else {
                  valueList.push(typeMap.get(typeList[i]).value[ii]);
                }
              }
            }
            obj["nameList"] = nameList;
            obj["valueList"] = valueList;
            this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)));
          }
        }
        for (let i = 0; i < this.showDeviceInfoList.length; i++) {
          for (let j = 0; j < this.showDeviceInfoList[i].nameList.length; j++) {
            let obj = {};
            obj.name = this.showDeviceInfoList[i].nameList[j];
            if (
              this.showDeviceInfoList[i].nameList[j] == "设备状态" &&
              this.showDeviceInfoList[i].valueList[j] == "正常" &&
              mapStatus != "" &&
              mapStatus != "正常"
            ) {
              obj.value = mapStatus;
            } else {
              obj.value = this.showDeviceInfoList[i].valueList[j];
            }
            this.changedDeviceInfoList.push(obj);
          }
        }
        if (isLost) {
          let length = this.changedDeviceInfoList.length;
          for (let i = 0; i < length; i++) {
            if (this.changedDeviceInfoList[i].name.indexOf("状态") == -1) {
              this.changedDeviceInfoList.splice(i, 1);
              i--;
            }
          }
        }
      }
      this.deviceId = device;
    },
    //水表数据导出
    waterMeterHistoryExport() {
      //获取导出的时间区间
      let timeArrays = this.getDateArray();
      let filterVal = ["deviceId", "date", "value", "meterReading"];
      filterVal = filterVal.concat(timeArrays);
      let th = ["设备ID", "日期", "用水量", "水表读数"];
      th = th.concat(timeArrays);
      //res.data.data.historyData

      // 一个对象代表一个sheet页
      let result = [
        {
          th: th, //表头
          data: this.formatJson(filterVal, this.historyData), //格式化的数据
          sheetTitle: "出库单号", // sheet名称
        },
      ];
      require.ensure([], () => {
        let {
          export2ExcelMultiSheet,
        } = require("../../introduce/Export2Excel");
        export2ExcelMultiSheet(result, "水表数据导出");
      });
    },

    // 格式化数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    getDateArray() {
      let timeArrays = new Array(48).fill("").map((item, index) => {
        let startVal = index * 30;
        let endVal = (index + 1) * 30;
        let startHour = Math.floor(startVal / 60);
        let startMinute = startVal % 60;
        let endHour = Math.floor(endVal / 60);
        let endMinute = endVal % 60;
        let startTime =
          (startHour < 10 ? "0" + startHour : startHour) +
          ":" +
          (startMinute === 0 ? "00" : startMinute);
        let endTime =
          (endHour < 10 ? "0" + endHour : endHour) +
          ":" +
          (endMinute === 0 ? "00" : endMinute);
        return startTime + "~" + endTime;
      });
      return timeArrays;
    },
    toggleTags(msg) {
      if (msg == "left") {
        this.flag = true;
      } else {
        this.flag = false;
        let obj = {};
        obj.deviceId = this.deviceAttribute.id;
        obj.startTime = this.dataTime_history[0];
        obj.endTime = this.dataTime_history[1];
        this.getWaterMeterHistory(obj);
      }
    },

    // 获取获取水表历史记录
    async getWaterMeterHistory(obj) {
      console.log(obj);
      const res = await DeviceHistoryValue.getWaterMeterHistory(obj);
      let data = res.data.data.historyData;
      let deviceId = obj.deviceId;
      //展示的数据集合
      let array = this.timeArrays;
      for (let i = 0; i < data.length; i++) {
        data[i].deviceId = deviceId;
        for (let j = 0; j < array.length; j++) {
          const element = array[j];
          if (!data[i].hasOwnProperty(element)) {
            data[i][element] = "无";
          }
        }
      }
      this.getMeterRead(obj, data);
    },

    async getMeterRead(obj, dataa) {
      let data = dataa;
      // 查询水表读数
      const res1 = await DeviceHistoryValue.getMeterReading(obj);
      let newList = [];
      newList = res1.data.data.list;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < newList.length; j++) {
          let date1 = newList[j].createTime.split(" ")[0];
          if (date1 == data[i].date) {
            data[i].meterReading = newList[j].value;
          }
        }
      }
      this.historyData = data; // 数据处理
      for (let i = 0; i < this.historyData.length; i++) {
        if (i === 0) {
          this.historyData[i].value = 0;
        } else {
          this.historyData[i].value =
            this.historyData[i].meterReading -
            this.historyData[i - 1].meterReading;
            this.historyData[i].value = this.historyData[i].value.toFixed(2)
        }
      }
      console.log(this.historyData);
    }, //获取一天每半小时时间
    getDateArray() {
      let timeArrays = new Array(48).fill("").map((item, index) => {
        let startVal = index * 30;
        let endVal = (index + 1) * 30;
        let startHour = Math.floor(startVal / 60);
        let startMinute = startVal % 60;
        let endHour = Math.floor(endVal / 60);
        let endMinute = endVal % 60;
        let startTime =
          (startHour < 10 ? "0" + startHour : startHour) +
          ":" +
          (startMinute === 0 ? "00" : startMinute);
        let endTime =
          (endHour < 10 ? "0" + endHour : endHour) +
          ":" +
          (endMinute === 0 ? "00" : endMinute);
        return startTime + "~" + endTime;
      });
      return timeArrays;
    },

    checkWater(type) {
      this.units = "m3";
      this.option = "用水";
      this.paramsObj.deviceTypeId = type; // 设备类型 水
      this.getDeviceInfo(type);
      this.getAcquisition()
    },
    checkElect(type) {
      this.units = "Kw·h";
      this.option = "用电";
      this.paramsObj.deviceTypeId = type; // 设备类型 电
      this.getDeviceInfo(type);
    },
    // radio
    checkRadio(value) {
      this.radio = value;
    },

    // 选择时间
    pickTime(value) {
      this.dataTime[0] = value[0];
      this.dataTime[1] = value[1];
    },

    pickTime_history(value) {
      this.dataTime_history[0] = value[0];
      this.dataTime_history[1] = value[1];
    },

    async getDeviceInfo(deviceType) {
      let obj = {};
      let dayTtype = "Daily"; // 时间类型
      let current = 1; // 当前页
      let limit = 999; // 条数
      obj.type = dayTtype;
      obj.deviceTypeId = "DXwaterMeter";
      // obj.startTime = this.dataTime[0]
      // obj.endTime = this.dataTime[1]
      obj.current = current;
      obj.limit = limit;
      const value = await DeviceHistoryValue.getWaterMeterData(obj);
      console.log(value);
      // //此处将设备对应的索引存入map
      for (let i = 0; i < value.data.data.list.length; i++) {
        const element = value.data.data.list[i];
        this.deviceMap.set(element.deviceId, i);
      }
      this.staticValueList = JSON.parse(JSON.stringify(value.data.data.list));

      const res = await DeviceHistoryValue.getHierarchicalList(deviceType, "");
      console.log(deviceType);
      console.log(res);
      this.deviceInfoList = res.data.data.list;
      console.log("-------+++++++----",this.deviceInfoList);
      this.soloveObj(this.deviceInfoList);

      // this.deviceInfoList.sort((a,b)=>(a.number-b.number))

      this.recursionSort(this.deviceInfoList);

      this.deviceInfo.deviceName = this.deviceInfoList[0].deviceName;
      let device = "";
      this.deviceInfo.deviceId != undefined
        ? (device = this.deviceInfo.deviceId)
        : (device = this.deviceInfoList[0].deviceId);

      // 默认查第一个公司的信息
      this.getDeviceData(device);

      this.dailyAndMonthData(device);

      this.getDeviceValue(device, deviceType);

      this.timer = setInterval(() => {
        this.changeDeviceStatus(
          this.deviceInfo.deviceId == undefined
            ? this.deviceInfoList[0].deviceId
            : this.deviceInfo.deviceId,
          this.deviceTypeId
        );
      }, 5000);
    },
    // 递归排序
    recursionSort(val) {
      val.sort((a, b) => a.number - b.number);
      let data = val;
      for (let i in data) {
        if (data[i].deviceList) {
          this.recursionSort(data[i].deviceList);
        }
      }
    },
    //处理数据
    soloveObj(list) {
      for (let i = 0; i < list.length; i++) {
        let element = list[i];
        let deviceId = element.deviceId;
        if (this.deviceMap.has(deviceId)) {
          let obj = this.staticValueList[this.deviceMap.get(deviceId)];
          let valueList = obj.valueList; //用水量集合数据
          let sumTotal = Number(0); //设置总用水量
          let coding = obj.coding;
          let number = obj.number;
          for (let j = 0; j < valueList.length; j++) {
            sumTotal += Number(valueList[j]);
          }
          element.sumTotal = Number(sumTotal).toFixed(2);
          element.leakage = "无";
          element.coding = coding;
          element.number = number;
          let sonSumTotal = Number(0); //子设备总用水
          if (obj.leakage != undefined) {
            for (const key in obj.leakage) {
              const element1 = obj.leakage[key];
              for (let j = 0; j < element1[0]["valueList"].length; j++) {
                sonSumTotal += Number(element1[0]["valueList"][j]);
              }
            }
            if (Number(sumTotal.toFixed(2)) > Number(sonSumTotal.toFixed(2))) {
              element.leakage =
                (
                  ((sumTotal.toFixed(2) - sonSumTotal.toFixed(2)) /
                    sumTotal.toFixed(2)) *
                  100
                ).toFixed(2) + "%";
            }
          }
          if (element.deviceList != undefined) {
            this.soloveObj(element.deviceList);
          }
        }
      }
    },

    // 选择设备过滤器
    filterNode(value, data) {
      if (
        data.deviceId.indexOf(value) != -1 ||
        (data.deviceName != undefined && data.deviceName.indexOf(value) != -1)
      ) {
        return true;
      } else {
        return false;
      }
    },

    // 选择设备
    getPickCompant(val) {
      this.titleName = val.deviceName;
      this.deviceInfo = val;
      this.deviceId = this.deviceInfo.deviceId;
      this.getDeviceData(this.deviceInfo.deviceId);
      this.dailyAndMonthData(this.deviceInfo.deviceId);
      this.getDeviceValue(this.deviceInfo.deviceId, this.deviceTypeId);
      this.getAcquisition()
      this.flag = true;
    },

    // 查设备数据 查询所有单位下所有设备数据  （默认 7 天）
    async getDeviceData(deviceId) {
      this.paramsObj.type = this.radio; // 日
      this.paramsObj.deviceTypeId = this.deviceTypeId; // 设备类型
      this.paramsObj.startTime = this.dataTime[0];
      this.paramsObj.endTime = this.dataTime[1];
      // this.paramsObj.current = this.current  // 当前页
      // this.paramsObj.limit = this.limit  // 条数
      this.paramsObj.deviceId = deviceId;
      const res = await DeviceHistoryValue.getWaterMeterData(this.paramsObj);
      this.deviceDataInfo = res.data.data.list;
      this.totalAcquisition(this.deviceDataInfo);

      this.deviceDataInfo.sort((a, b) => a.number - b.number);
      // 所有公司
      const company = await companySystem.getCompanyInfo();
      let companyList = company.data.data.list;

      // 所有系统
      const system = await noticeSysManagement.getNotifyBigSystemById();
      let systemList = system.data.data.list;

      if (res.data.message == "成功") {
        // 设备信息
        const res1 = await DeviceInfoManagement.getDeviceInfoById(deviceId);
        this.deviceAttribute = res1.data.data.deviceInfoVo;
        for (let i = 0; i < this.deviceAttribute.device.length; i++) {
          if (this.deviceAttribute.device[i].proName == "布防状态") {
            this.deviceAttribute.device[i].value == 1
              ? (this.deviceAttribute.device[i].value = "布防")
              : (this.deviceAttribute.device[i].value = "撤防");
          }
          if (this.deviceAttribute.device[i].proName == "是否同步添加") {
            this.deviceAttribute.device[i].value == 1
              ? (this.deviceAttribute.device[i].value = "是")
              : (this.deviceAttribute.device[i].value = "否");
          }
          if (this.deviceAttribute.device[i].proName == "所属系统") {
            for (let j = 0; j < systemList.length; j++) {
              if (this.deviceAttribute.device[i].value == systemList[j].id) {
                this.deviceAttribute.device[i].value =
                  systemList[j].bigSystemName;
              }
            }
          }
          if (this.deviceAttribute.device[i].proName == "绑定公司") {
            this.deviceAttribute.device[i].proName = "单位";
            for (let j = 0; j < companyList.length; j++) {
              if (this.deviceAttribute.device[i].value == companyList[j].id) {
                this.deviceAttribute.device[i].value = companyList[j].name;
              }
            }
          }
        }
      }
    },
    async dailyAndMonthData(deviceId) {
      // 获取当日用水量
      this.paramsObj.type = "Daily"; // 日
      this.paramsObj.deviceTypeId = this.deviceTypeId; // 设备类型
      this.paramsObj.startTime = this.$moment().format("YYYY-MM-DD 00:00:00");
      this.paramsObj.endTime = this.$moment().format("YYYY-MM-DD 23:59:59");
      this.paramsObj.deviceId = deviceId;
      const daily = await DeviceHistoryValue.getWaterMeterData(this.paramsObj);
      this.dailyData = 0;
      this.dailyData = Number(daily.data.data.list[0].valueList[0]);

      // 获取昨日用水量
      this.paramsObj.type = "Daily"; // 日
      this.paramsObj.deviceTypeId = this.deviceTypeId; // 设备类型
      this.paramsObj.startTime = this.$moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD 00:00:00");
      this.paramsObj.endTime = this.$moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD 23:59:59");
      this.paramsObj.deviceId = deviceId;
      const yesterday = await DeviceHistoryValue.getWaterMeterData(
        this.paramsObj
      );
      this.yesterdayData = 0;
      this.yesterdayData = Number(yesterday.data.data.list[0].valueList[0]);

      // 获取当月用水量
      this.paramsObj.type = "Daily"; // 日
      this.paramsObj.deviceTypeId = this.deviceTypeId; // 设备类型
      this.paramsObj.startTime = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.paramsObj.endTime = this.$moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      this.paramsObj.deviceId = deviceId;
      const month = await DeviceHistoryValue.getWaterMeterData(this.paramsObj);
      this.monthDate = (
        month.data.data.list[0].timeList[0] +
        " ~ " +
        month.data.data.list[0].timeList[
          month.data.data.list[0].timeList.length - 1
        ]
      ).toString();
      this.monthData = 0;
      for (let i = 0; i < month.data.data.list[0].valueList.length; i++) {
        this.monthData += Number(month.data.data.list[0].valueList[i]);
      }

      // 获取上月用水量
      this.paramsObj.type = "Daily"; // 日
      this.paramsObj.deviceTypeId = this.deviceTypeId; // 设备类型
      this.paramsObj.startTime = this.$moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.paramsObj.endTime = this.$moment(new Date())
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      this.paramsObj.deviceId = deviceId;
      const monthly = await DeviceHistoryValue.getWaterMeterData(
        this.paramsObj
      );
      this.lastMonthDate = (
        monthly.data.data.list[0].timeList[0] +
        " ~ " +
        monthly.data.data.list[0].timeList[
          monthly.data.data.list[0].timeList.length - 1
        ]
      ).toString();
      this.monthlyData = 0;
      for (let i = 0; i < monthly.data.data.list[0].valueList.length; i++) {
        this.monthlyData += Number(monthly.data.data.list[0].valueList[i]);
      }
    },

    // 处理数据，计算总用量
    totalAcquisition(list) {
      // 所选时间段内总用水、电量
      console.log(this.dataTime);
    },
    async getAcquisition(){
      let obj = {
        deviceId: this.deviceId,
        startTime: this.dataTime[0],
        endTime: this.dataTime[1],
      }
      const res = await DeviceHistoryValue.getWaterMeterConsumption(obj)
      console.log(res.data.data.data.periodData);
      this.acquisition = res.data.data.data.periodData
      console.log("-------------------------");
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/uiCss.scss";

@font-face {
  font-family: "led regular";
  src: url("../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 90vh;
  display: flex;

  .left {
    box-sizing: border-box;
    width: 15%;
    height: 100%;
    padding: 10px 5px;
    border-radius: 5px;

    .search {
      height: 11%;
      width: 100%;

      div {
        font-size: 20px;
        font-family: "宋体";
        font-weight: 600;
        color: #0ce3eb;
        margin: 10px;
      }
    }

    .info-list {
      margin-top: 1%;
      height: 88%;
      width: 100%;
      overflow: scroll;

      /deep/.el-tree-node__content {
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        background: none;
      }

      .el-tree {
        color: #80ecf0;
        background: none;
      }

      /deep/.el-tree-node__content:hover {
        //   background: rgb(48, 79, 145);
        background: #fff;
        color: #035e61;
        font-weight: 600;
        border-radius: 3px;
      }

      /deep/.el-tree-node__content:hover {
        background: rgba(113, 213, 238, 0.5);
      }
    }
  }

  .content {
    box-sizing: border-box;
    padding: 20px 0 0 30px;
    width: 85%;
    height: 100%;

    .device-rate {
      width: 100%;
      height: 25%;
      box-sizing: border-box;

      // border: 1px solid red;
      .titles {
        color: #0ce3eb;
        font-size: 20px;
        font-weight: 600;
        font-family: "宋体";
      }

      .line {
        display: flex;
        height: 75%;

        .total {
          margin-right: 20px;
          width: 200px;
          height: 100%;
          color: #0ce3eb;
          // border: 1px solid rgb(152, 231, 106);
          border-radius: 10px;
          background: rgba(1, 29, 36, 0.1);
          box-sizing: border-box;
          padding: 0 10px;

          .title {
            font-size: 18px !important;
            font-family: "宋体";
          }

          .num {
            width: 100%;
            height: 80px;
            line-height: 70px;
            text-align: center;
            font-size: 45px;
            font-family: "led regular";
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }

          .unity {
            float: right;
            font-size: 20px;
            font-family: "宋体";
          }
        }

        .lefts {
          box-sizing: border-box;
          width: 40%;
          height: 100%;
          margin: 0 5px;
          border-radius: 5px;
          padding: 0 10px;
          background: rgba(1, 29, 36, 0.1);

          .title {
            font-size: 18px;
          }

          .rows {
            font-size: 16px;
            color: #80ecf0;

            div {
              height: 30px;
              line-height: 30px;
              border-bottom: 1px dotted #57c0c2;

              span {
                float: right;
              }
            }
          }
        }
      }
    }

    .device-statistic {
      width: 100%;
      height: 35%;
      margin-top: 20px;
      box-sizing: border-box;
      // padding: 20px 10px;
      // position: absolute;
      // bottom: 10px;

      .titles {
        color: #0ce3eb;
        font-size: 20px;
        font-weight: 600;
        font-family: "宋体";
      }
    }

    .device-info {
      width: 100%;
      height: 18%;
      box-sizing: border-box;

      // padding: 20px 10px;
      .titles {
        color: #0ce3eb;
        font-size: 20px;
        font-weight: 600;
        font-family: "宋体";
      }

      .infolist {
        height: 110px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        overflow: scroll;

        // .item {
        //   font-size: 17px;
        //   color: #0ce3eb;
        //   padding: 0 50px 0 10px;
        //   margin: 10px 0;
        // }
        .item{
          display: inline-block;
          margin: 0 10px;
          padding: 3px 7px;
          height: 30px;
          line-height: 30px;
          background-color: rgba($color: #397de2, $alpha: 0.25);
          border-radius: 4px;
          color: #1fc6a5;
         box-shadow: inset -2px -2px 2px #1c7ccf;
         cursor: default;
         letter-spacing:1.5px;
        }
      }
    }

    .title {
      color: #0ce3eb;
      line-height: 45px;
      font-size: 22px;
      font-weight: 600;
      font-family: "宋体";
      cursor: pointer;

      span:hover {
        color: #d3b548;
      }
    }

    .banner {
      box-sizing: border-box;
      padding-top: 15px;
      width: 100%;
      height: 60px;

      .el-radio {
        margin: 0 0px 0 10px;
      }
    }

    // .data-show {
    //   position: relative;
    //   width: 100%;
    //   height: 85%;
    //   .item1 {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

/deep/.el-table thead.is-group th.el-table__cell {
  background: rgba(53, 110, 148, 0.4);
  border: none;
}

/deep/ .el-table tr{
  backdrop-filter: blur(10px);
}
.history {
  width: 100%;
  height: 90%;
  color: #ccc;
  box-sizing: border-box;

  .history-info {
    box-sizing: border-box;
    width: 100%;
    height: 90%;

    ::-webkit-scrollbar {
      display: block;
    }
  }
}

/deep/.el-table--border::after,
.el-table--group::after {
  width: 0px;
}
</style>