let data1 = [
  // *********** 1
  {
    name: '结构1',
    value: 1,
    children: [
      // *********** 1-1
      {
        name: '结构1-1',
        value: 11,
      },
      // *********** 1-2
      {
        name: '结构1-2',
        value: 12,
        children: [
          // *********** 1-2-1
          {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          }
        ]
      },
      // *********** 1-3
      {
        name: '结构1-3',
        value: 13,
        children: [
          // *********** 1-2-1
          {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          }
        ]
      }
    ]
  },
  // *********** 2
  {
    name: '结构2',
    value: 2,
    position: [117.205791,39.097151],
  },
  // *********** 3
  {
    name: '结构3',
    value: 3,
    children: [
      // *********** 1-2-1
      {
        name: '结构1-2-1',
        value: 121,
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
      },
      {
        name: '结构1-2-1',
        value: 121,
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
      },
      {
        name: '结构1-2-1',
        value: 121,
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
      }, {
        name: '结构1-2-1',
        value: 121,
        children: [
          // *********** 1-2-1
          {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          },
          {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          },
          {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
            children: [
              // *********** 1-2-1
              {
                name: '结构1-2-1',
                value: 121,
              },
              // *********** 1-2-2
              {
                name: '结构1-2-2',
                value: 122,
              },
              {
                name: '结构1-2-1',
                value: 121,
              },
              // *********** 1-2-2
              {
                name: '结构1-2-2',
                value: 122,
              },
              {
                name: '结构1-2-1',
                value: 121,
              },
              // *********** 1-2-2
              {
                name: '结构1-2-2',
                value: 122,
              }, {
                name: '结构1-2-1',
                value: 121,
                children: [
                  // *********** 1-2-1
                  {
                    name: '结构1-2-1',
                    value: 121,
                  },
                  // *********** 1-2-2
                  {
                    name: '结构1-2-2',
                    value: 122,
                  },
                  {
                    name: '结构1-2-1',
                    value: 121,
                  },
                  // *********** 1-2-2
                  {
                    name: '结构1-2-2',
                    value: 122,
                  },
                  {
                    name: '结构1-2-1',
                    value: 121,
                  },
                  // *********** 1-2-2
                  {
                    name: '结构1-2-2',
                    value: 122,
                  }, {
                    name: '结构1-2-1',
                    value: 121,
                  },
                  // *********** 1-2-2
                  {
                    name: '结构1-2-2',
                    value: 122,
                  },
                  // *********** 1-2-2
                  {
                    name: '结构1-2-2',
                    value: 122,
                  }, {
                    name: '结构1-2-1',
                    value: 121,
                  },
                  // *********** 1-2-2
                  {
                    name: '结构1-2-2',
                    value: 122,
                  }
                ]
              },
              // *********** 1-2-2
              {
                name: '结构1-2-2',
                value: 122,
              },
              // *********** 1-2-2
              {
                name: '结构1-2-2',
                value: 122,
              }, {
                name: '结构1-2-1',
                value: 121,
              },
              // *********** 1-2-2
              {
                name: '结构1-2-2',
                value: 122,
              }
            ]
          }, {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          }, {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          }
        ]
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
      }, {
        name: '结构1-2-1',
        value: 121,
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
      }
    ]
  },
  // *********** 4
  {
    name: '结构4',
    value: 4,
    children: [
      // *********** 1-2-1
      {
        name: '结构1-2-1',
        value: 121,
      },
      // *********** 1-2-2
      {
        name: '结构1-2-2',
        value: 122,
        children: [
          // *********** 1-2-1
          {
            name: '结构1-2-1',
            value: 121,
          },
          // *********** 1-2-2
          {
            name: '结构1-2-2',
            value: 122,
          }
        ]
      }
    ]
  }
]

export default {
  data1
}