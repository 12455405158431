<template>
  <div id="app">

    <div class="container" id="main4">
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: ['dateListX', 'valueWaterListY', 'valueElectListY'],
  data() {
    return {
      timeList: [],
      list1: [],
      list2: [],
    }
  },
  created() {
    console.log(this.valueWaterListY);
    for (let i = 0; i < this.dateListX.length; i++) {
      this.timeList.push(this.dateListX[i].split('-').splice(1, 2).join('-'))
      let obj1 = {}
      obj1.name = this.timeList[i]
      obj1.value = this.valueWaterListY[i]
      this.list1.push(obj1)

    }
  },
  mounted() {
    this.initCharts()
  },
  methods: {
    initCharts() {
      var chartDom = document.getElementById('main4');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          left: 'left',
          text: '30天、日用水量占比',
          textStyle: {
            color: 'aqua'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          top: 'bottom',
          textStyle: { color: "#fff" }
        },
        toolbox: {
          iconStyle: {
            color: "white",
          },
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: '日期: 数值（占比）',
            type: 'pie',
            radius: [50, 100],
            center: ['50%', '40%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: this.list1
          }
        ]
      };
      option && myChart.setOption(option);

    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
}
</style>