<template>
  <div id="app">
    <div class="container">
      <div class="title"><i class="iconfont icon-iotechyouhao2"></i> {{ title }}</div>
      <div class="content">
        <div class="content-item">
          <span>年用水量（单位：m³）</span>
          <span>{{ yearData.toFixed(2) }}</span>
        </div>
        <div class="content-item">
          <span>月用水量（单位：m³）
            <span>{{ monthData.toFixed(2) }}</span>
          </span>
          <span>
            <slot name="btn"></slot>
          </span>

        </div>
        <div class="content-item">
          <span>昨日用水量（单位：m³）
            <span>{{ dayData.toFixed(2) }}</span>
          </span>
          <span>
            <slot name="btn1"></slot>
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['title', 'yearData', 'monthData', 'dayData'],
  components: {},
  data() {
    return {
    };
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "led regular";
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  padding: 20px;
  height: 90%;

  .title {
    color: aqua;
    letter-spacing: 2px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }


  .content {
    width: 100%;
    height: 100%;
    // border: 1px solid red;

    .content-item {
      box-sizing: border-box;
      letter-spacing: 1px;

      color: aliceblue;
      height: 30%;
      // border: 2px solid rgba(3, 92, 151, 0.5);
      // margin: 1% 0;
      // box-shadow: 2px 2px 5px rgba(3, 92, 151, 0.5);
    }

    .content-item:nth-child(1) {
      height: 28%;
      margin-bottom: 10px;
      border: 2px solid rgba(3, 92, 151, 0.5);
      box-shadow: 2px 2px 5px rgba(3, 92, 151, 0.5);
    }

    .content-item span:nth-child(1) {
      box-sizing: border-box;
      display: inline-block;
      font-size: 17px;
      padding: 10px;
    }

    .content-item:nth-child(1) span:nth-child(1) {
      box-sizing: border-box;
    }



    .content-item:nth-child(1) span:nth-child(2) {
      box-sizing: border-box;
      width: 35%;
      font-size: 65px;
      height: 90%;
      float: right;
      text-align: right;
      padding: 5px;
      line-height: 130px;
      display: inline-block;
      // color: rgb(43, 174, 226);
      color: aliceblue;
      font-family: 'led regular';
    }

    .content-item:nth-child(2) span:nth-child(1) {
      box-sizing: border-box;
      width: 78%;
      height: 90%;
      border: 2px solid rgba(3, 92, 151, 0.5);
      box-shadow: 2px 2px 5px rgba(3, 92, 151, 0.5);
      display: inline-block;

      span {
        display: inline-block;
        width: 40%;
        border: none;
        line-height: 60px;
        text-align: right;
        font-family: 'led regular';
        font-size: 60px;
        box-shadow: none;
        float: right;
      }
    }

    .content-item:nth-child(2) span:nth-child(2) {
      box-sizing: border-box;
      width: 20%;
      height: 90%;
      float: right;
      padding: 5px;
      border: 2px solid rgba(3, 92, 151, 0.5);
      box-shadow: 2px 2px 5px rgba(3, 92, 151, 0.5);
      display: inline-block;
    }


    .content-item:nth-child(3) span:nth-child(1) {
      box-sizing: border-box;
      width: 78%;
      height: 90%;
      border: 2px solid rgba(3, 92, 151, 0.5);
      box-shadow: 2px 2px 5px rgba(3, 92, 151, 0.5);
      display: inline-block;

      span {
        display: inline-block;
        width: 40%;
        border: none;
        line-height: 60px;
        text-align: right;
        font-family: 'led regular';
        font-size: 60px;
        box-shadow: none;
        float: right;
      }
    }

    .content-item:nth-child(3) span:nth-child(2) {
      box-sizing: border-box;
      width: 20%;
      height: 90%;
      float: right;
      padding: 5px;
      border: 2px solid rgba(3, 92, 151, 0.5);
      box-shadow: 2px 2px 5px rgba(3, 92, 151, 0.5);
      display: inline-block;
    }
  }
}
</style>