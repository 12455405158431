<template>
  <div id="app">
    <div class="charts" id="main0"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: ['dateListX', 'valueWaterListY', 'valueElectListY'],
  data() {
    return {
      avgWater: [],
      avgElect: [],

    }
  },
  created() {
    for (let i = 0; i < this.valueWaterListY.length; i++) {
      this.avgWater.push(
        Number(this.valueWaterListY[i]) / this.dateListX.length
      )
    }
    for (let i = 0; i < this.valueElectListY.length; i++) {
      this.avgElect.push(
        Number(this.valueElectListY[i]) / this.dateListX.length
      )
    }

  },
  mounted() {
    this.loadChart()

  },


  methods: {
    loadChart() {

      var chartDom = document.getElementById('main0');
      var myChart = echarts.init(chartDom);
      var option;
      const colors = ['#5470C6', '#91CC75', '#EE6666', '#008c8c'];

      option = {
        title: {
          left: 'left',
          text: '30天 用水量统计',
          textStyle: {
            color: 'aqua'
          }
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          iconStyle: {
            color: "white",
          },
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['折线', '柱状']
        },
        xAxis: [
          {
            type: 'category',
            data: this.dateListX,
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#008c8c'
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '用水量 m3',
            axisLabel: {
              formatter: '{value} m3'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
                fontSize: '23px'
              }
            },
          },
        ],
        series: [
          {
            name: '折线',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' m3';
              }
            },
            data: this.valueWaterListY
          },
          {
            name: '柱状',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' m3';
              }
            },
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#81ecec',
                    fontSize: 16
                  }
                }
              }
            },
            data: this.valueWaterListY
          },
        ]
      };
      option && myChart.setOption(option);
    }
  }

}
</script>

<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 100%;
}
</style>