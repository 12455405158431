<template>
  <div id="app">

    <div class="container" id="main5">
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: ['totalWater', 'totalElect'],
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
    this.initCharts()
  },
  methods: {
    initCharts() {

      var chartDom = document.getElementById('main5');
      var myChart = echarts.init(chartDom);
      var option;

      const colors = ['#5470C6', '#91CC75'];
      option = {
        title: {
          left: 'left',
          text: '30天 总用水量用电量',
          textStyle: {
            color: 'aqua'
          }
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '20%'
        },
        toolbox: {
          iconStyle: {
            color: "white",
          },
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['用水量', '用电量']
        },

        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            },
            axisTick: {
              alignWithLabel: true
            },
            data: ['水、电用量']
          }
        ],

        yAxis: [
          {
            type: 'value',
            name: '用水量',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value} m3'
            }
          },
          {
            type: 'value',
            name: '用电量',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1]
              }
            },
            axisLabel: {
              formatter: '{value} Kw/h'
            }
          },


        ],
        series: [

          {
            name: '用水量',
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#81ecec',
                    fontSize: 16
                  }
                }
              }
            },
            data: [
              this.totalWater
            ]
          },
          {
            name: '用电量',
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#81ecec',
                    fontSize: 16
                  }
                }
              }
            },
            yAxisIndex: 1,
            data: [
              this.totalElect
            ]
          },

        ]
      };

      option && myChart.setOption(option);

    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
}
</style>