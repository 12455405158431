<template>
  <div id="app">
    <div class="switch">
      <div class="title">是否开启跳转模式：</div>
      当前状态：<el-switch v-model="open" active-text="开启" inactive-text="关闭">
      </el-switch>
    </div>
    <div class="container" id="main"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import DevicRelationship from './DevicRelationship.js'

export default {
  data() {
    return {
      open: false,
    }
  },
  watch: {

  },
  created() {
  },

  mounted() {
    this.initCharts()
  },

  methods: {
    // 初始化 echart
    initCharts() {
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      option = DevicRelationship.datas
      myChart.on("click", (param) => {
        console.log(param.data);
        if (this.open) {
          this.$emit('propDevice', param.data)
        }
      })
      option && myChart.setOption(option);
    },

  }

}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 90vh;
  display: flex;
  // border: 1px solid red;

}

.switch {
  color: aliceblue;
  position: absolute;
  top: 90px;
  z-index: 99;
  padding: 10px;
  cursor: pointer;
  letter-spacing: 1px;

  .title {
    font-size: 17px;
    padding: 10px 0;
  }
}
</style>