<!-- 水电管理系统入口页文件 -->
<template>
  <div id="app">
    <div class="container">
      <div class="header" style="height: 80px">
        <div class="title">
          <div class="item"></div>
          <!-- 标题 -->
          <div class="item">
            {{ titleText }}
            <!-- 油港能源计量采集分析系统 -->
            <!-- {{platformInfo.platformName}} -->
          </div>
          <div class="item">
            {{ times }}
          </div>
        </div>
        <!-- 顶部导航栏 -->
        <div class="menu">
          <span
            v-for="(item, i) in toggleLit"
            :key="i"
            @click="togglePage(item.name)"
            :style="
              item.name == currentName ? 'color:#d3b548;font-weight:900' : ''
            "
            >{{ item.name }}</span
          >
        </div>
        <!-- 返回按钮 -->
        <span
          class="iconfont icon-iotechfanhui icon"
          @click="$router.back()"
        ></span>
      </div>
      <!-- 主页数据 -->
      <div class="content" v-if="flag1">
        <div class="line1" v-if="flag">
          <div class="left">
            <!-- 30天日用水量占比 -->
            <TopWater
              :dateListX="dateListX"
              :valueWaterListY="valueWaterListY"
              :valueElectListY="valueElectListY"
            >
            </TopWater>
          </div>
          <!-- 用水数据统计 loading -->
          <div class="right" v-loading="false">
            <data-statistics
              title="用水数据统计"
              :yearData="yearData"
              :monthData="monthData"
              :dayData="dayData"
            >
              <!-- <template #btn>
                <div class="slot" v-for="item, i in slotDiv1" :index="i" style="margin-top: 10px;">
                  {{ item.name }}：
                  <i class="iconfont icon" :class="item.iconLeft" @click="timeQuery(item, 'sub', 'month')"></i>
                  <input type="text" disabled v-model="item.val" style="width: 60px;text-align: center;">
                  <i class="iconfont icon" :class="item.iconRight" @click="timeQuery(item, 'add', 'month')"></i>
                </div>
                                                                                                  </template> -->

              <!-- <template #btn1>
                <div class="slot" v-for="item, i in slotDiv" :index="i">
                  {{ item.name }}：
                  <i class="iconfont icon" :class="item.iconLeft" @click="timeQuery(item, 'sub', 'day')"></i>
                  <input type="text" disabled v-model="item.val" style="width: 60px;text-align: center;">
                  <i class="iconfont icon" :class="item.iconRight" @click="timeQuery(item, 'add', 'day')"></i>
                </div>
                                                                                                  </template> -->
              <!-- 月用水量的年月选择器 -->
              <template #btn>
                <div
                  class="slot"
                  v-for="(item, i) in slotDiv1"
                  :key="i"
                  :index="i"
                  style="margin-top: 10px"
                >
                  {{ item.name }}：
                  <i
                    class="iconfont icon"
                    :class="item.iconLeft"
                    @click="monthlySub(item)"
                  ></i>
                  <input
                    type="text"
                    disabled
                    v-model="item.val"
                    style="width: 60px; text-align: center"
                  />
                  <i
                    class="iconfont icon"
                    :class="item.iconRight"
                    @click="monthlyAdd(item)"
                  ></i>
                </div>
              </template>
              <!-- 昨日用水量的时间选择器 -->
              <template #btn1>
                <div class="slot" v-for="(item, i) in slotDiv" :index="i">
                  {{ item.name }}：
                  <i
                    class="iconfont icon"
                    :class="item.iconLeft"
                    @click="monthlySub1(item)"
                  ></i>
                  <input
                    type="text"
                    disabled
                    v-model="item.val"
                    style="width: 60px; text-align: center"
                  />
                  <i
                    class="iconfont icon"
                    :class="item.iconRight"
                    @click="monthlyAdd1(item)"
                  ></i>
                </div>
              </template>
            </data-statistics>
          </div>
        </div>
        <!-- 30天用水量统计 -->
        <div class="line2" v-if="flag">
          <div class="left">
            <BtmWater
              :dateListX="dateListX"
              :valueWaterListY="valueWaterListY"
              :valueElectListY="valueElectListY"
            >
            </BtmWater>
          </div>
        </div>
      </div>
      <!-- 设备监控 -->
      <div v-else-if="flag2">
        <WaterElectricityDataAnalyze></WaterElectricityDataAnalyze>
      </div>
      <!-- 设备监测 -->
      <div v-else-if="flag3">
        <WaterElectricityDeviceAnalyze
          :propDeviceInfo="propDeviceInfo"
        ></WaterElectricityDeviceAnalyze>
      </div>
      <!-- 设备分布 -->
      <div v-else-if="flag4" style="width:100%;height:91%;">
        <DeviceMap></DeviceMap>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";
// 组件导入
// 用水
import TopWater from "@/components/WaterAndElectricity/Left/TopWater.vue";
import BtmWater from "@/components/WaterAndElectricity/Left/BtmWater.vue";
// 用电
import TopElect from "@/components/WaterAndElectricity/Right/TopElect.vue";
import BtmElect from "@/components/WaterAndElectricity/Right/BtmElect.vue";
// 数据分析
import WaterElectricityDataAnalyze from "@/components/WaterAndElectricity/WaterElectricityDataAnalyze.vue";
// 设备分析
import WaterElectricityDeviceAnalyze from "@/components/WaterAndElectricity/WaterElectricityDeviceAnalyze";
// 历史记录
// import WaterElectricityDeviceHistory from '@/components/WaterAndElectricity/WaterElectricityDeviceHistory'

// 设备分布
import DeviceMap from "@/components/WaterAndElectricity/DeviceMap";
import DevicRelationship from "@/components/WaterAndElectricity/DevicRelationship.vue";
//
import Center from "@/components/WaterAndElectricity/Center";

// 用水数据统计组件
import DataStatistics from "@/components/WaterAndElectricity/Right/DataStatisticsOfWater.vue";

import AccountManage from "@/api/manageApi/AccountManage";
import user from "@/api/manageApi/User";

export default {
  components: {
    TopWater,
    BtmWater,
    TopElect,
    BtmElect,
    WaterElectricityDataAnalyze,
    WaterElectricityDeviceAnalyze,
    // WaterElectricityDeviceHistory,
    DeviceMap,
    Center,
    DevicRelationship,
    DataStatistics,
  },

  data() {
    return {
      slotDiv: [
        {
          name: "年",
          iconLeft: "icon-iotechxiangzuo",
          val: new Date().getFullYear(),
          iconRight: "icon-iotechxiangyou",
        },
        {
          name: "月",
          iconLeft: "icon-iotechxiangzuo",
          val: new Date().getMonth() + 1,
          iconRight: "icon-iotechxiangyou",
        },
        {
          name: "日",
          iconLeft: "icon-iotechxiangzuo",
          val: new Date().getDate() - 1,
          iconRight: "icon-iotechxiangyou",
        },
      ],

      slotDiv1: [
        {
          name: "年",
          iconLeft: "icon-iotechxiangzuo",
          val: new Date().getFullYear(),
          iconRight: "icon-iotechxiangyou",
        },
        {
          name: "月",
          iconLeft: "icon-iotechxiangzuo",
          val: new Date().getMonth() + 1,
          iconRight: "icon-iotechxiangyou",
        },
      ],

      titleText: "",
      users: "", // 当前账号用户名
      times: null,
      timer: null,

      flag: false,
      flag1: true,
      flag2: false,
      flag3: false,
      flag4: false,
      // 导航栏名称
      currentName: "数据总览",
      toggleLit: [
        {
          name: "数据总览",
        },
        {
          name: "设备监控",
        },
        {
          name: "设备监测",
        },
        {
          name: "设备分布",
        },
      ],

      dataTime: [
        this.$moment().add(-29, "d").format("YYYY-MM-DD HH:mm:ss"),
        this.$moment().add(0, "d").format("YYYY-MM-DD HH:mm:ss"),
      ],
      paramsObj: {},
      radio: "Daily",
      current: 1,
      limit: 999,

      DeviceHistoryWaterList: [], // 用水量
      DeviceHistoryElectList: [], // 用电量

      // 水
      ciIdList: [], //公司id集合
      valueInfoList: [], //公司id对应的用水量信息集合
      // 电
      ciIdList1: [], //公司id集合
      valueInfoList1: [], //公司id对应的用水量信息集合

      totalWater: null, // 总用水量
      totalElect: null, // 总用电量

      dateListX: [],
      valueWaterListY: [],
      valueElectListY: [],

      platformInfo: [],

      // 设备关系中的数据标签对象
      propDeviceInfo: {},

      requestData: {},

      loading: true,

      // 年月日数据
      yearData: 0,
      monthData: 0,
      dayData: 0,
    };
  },

  created() {

    this.getAccount();
    this.times = this.$moment().format("lll");
    this.timer = setInterval(() => {
      this.times = this.$moment().format("lll");
    }, 1000);
    // 查一个月水表数据
    this.getDeviceDataOfWater();
    // 查一个月电表数据
    this.getDeviceDataOfElect();

    // 设备分布结构跳转参数
    if (this.$route.query.data) {
      this.togglePage("设备监测");
      this.propDeviceInfo = data;
    }
  },

  mounted() {
    this.getYearData();
    this.getMonthData();
    this.getDayData();
  },

  methods: {
    // 包含年和月的方法
    monthlySub(item) {
      if (item.name == "年") {
        item.val--;
      } else if (item.name == "月") {
        if (item.val > 1 && item.val <= 12) {
          item.val--;
        }
      }
      let time = this.slotDiv1[0].val + "-" + this.slotDiv1[1].val;
      this.getMonthData(time);
    },
    monthlyAdd(item) {
      if (item.name == "年") {
        item.val++;
      } else if (item.name == "月") {
        if (item.val >= 1 && item.val < 12) {
          item.val++;
        }
      }
      let time = this.slotDiv1[0].val + "-" + this.slotDiv1[1].val;
      this.getMonthData(time);
    },
    // 不包含年的方法
    monthlySub1(item) {
      // 计算当前月的天数
      let setDate = new Date();
      let year = setDate.getFullYear();
      let month = setDate.getMonth() + 1;
      var day = new Date(year, month, 0).getDate();
      if (item.name == "年") {
        item.val--;
      } else if (item.name == "月") {
        if (item.val > 1 && item.val <= 12) {
          item.val--;
        }
      } else {
        if (item.val > 1 && item.val < day) {
          item.val--;
        }
      }
      let time =
        this.slotDiv[0].val +
        "-" +
        this.slotDiv[1].val +
        "-" +
        this.slotDiv[2].val;
      this.getDayData(time);
    },
    monthlyAdd1(item) {
      // 计算当前月的天数
      let setDate = new Date();
      let year = setDate.getFullYear();
      let month = setDate.getMonth() + 1;
      var day = new Date(year, month, 0).getDate();
      if (item.name == "年") {
        item.val++;
      } else if (item.name == "月") {
        if (item.val >= 1 && item.val < 12) {
          item.val++;
        }
      } else {
        if (item.val >= 1 && item.val < day) {
          item.val++;
        }
      }
      let time =
        this.slotDiv[0].val +
        "-" +
        this.slotDiv[1].val +
        "-" +
        this.slotDiv[2].val;
      this.getDayData(time);
    },

    // 查询当前年的数据
    // 当年数据是当月数据之和
    async getYearData() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      month > 10 ? month : (month = "0" + month);
      let obj = {};
      obj.startTime = year + "-" + "01"; // 起始时间
      obj.endTime = year + "-" + month; // 结束时间
      obj.type = "Monthly";
      obj.deviceTypeId = "DXwaterMeter";
      obj.current = this.current;
      obj.limit = this.limit;
      const res = await DeviceHistoryValue.getWaterMeterData(obj);
      let yearList = [];
      for (let i = 0; i < res.data.data.list.length; i++) {
        if (
          res.data.data.list[i].deviceId == 861821066601227 ||
          res.data.data.list[i].deviceId == 861821066549723
        ) {
          yearList.push(res.data.data.list[i]);
        }
      }
      // let yearList = res.data.data.list
      this.yearData = 0;

      // 循环设备
      // for (let j = 0; j < yearList[i].valueList.length; j++){
      //   // 循环选择日期
      //   for (let i = 0; i < yearList.length; i++) {

      //   }
      // }

      // 循环2个设备
      for (let i = 0; i < yearList.length; i++) {
        let sum = 0;
        // 循环4个月份
        for (let j = 0; j < yearList[i].valueList.length; j++) {
          sum += Number(yearList[i].valueList[j]);
        }
        // sum 44个设备的年用水量
        yearList[i].sum = sum;
        this.yearData += yearList[i].sum;
      }
    },
    // 查询当前月的数据
    async getMonthData(time) {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      month > 10 ? month : (month = "0" + month);
      let obj = {};
      if (time == undefined) {
        obj.startTime = year + "-" + month; // 起始时间
        obj.endTime = year + "-" + month; // 结束时间
      } else {
        obj.startTime = time;
        obj.endTime = time;
      }
      this.monthData = 0;
      obj.type = "Monthly";
      obj.deviceTypeId = "DXwaterMeter";
      obj.current = this.current;
      obj.limit = this.limit;
      const res = await DeviceHistoryValue.getWaterMeterData(obj);
      let monthList = [];
      for (let i = 0; i < res.data.data.list.length; i++) {
        if (
          res.data.data.list[i].deviceId == 861821066601227 ||
          res.data.data.list[i].deviceId == 861821066549723
        ) {
          monthList.push(res.data.data.list[i]);
        }
      }
      // let monthList = res.data.data.list
      for (let i = 0; i < monthList.length; i++) {
        if (monthList[i].valueList.length != 0)
          this.monthData += Number(monthList[i].valueList[0]);
      }
    },
    // 查询当前日的数据
    async getDayData(time) {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      month > 10 ? month : (month = "0" + month);
      day > 10 ? day : (day = "0" + day);
      let obj = {};
      if (time == undefined) {
        obj.startTime = year + "-" + month + "-" + (day - 1); // 起始时间
        obj.endTime = year + "-" + month + "-" + (day - 1); // 结束时间
      } else {
        obj.startTime = time;
        obj.endTime = time;
      }
      obj.type = "Daily";
      obj.deviceTypeId = "DXwaterMeter";
      obj.current = this.current;
      obj.limit = this.limit;
      this.dayData = 0;
      const res = await DeviceHistoryValue.getWaterMeterData(obj);
      let dayList = [];
      for (let i = 0; i < res.data.data.list.length; i++) {
        if (
          res.data.data.list[i].deviceId == 861821066601227 ||
          res.data.data.list[i].deviceId == 861821066549723
        ) {
          dayList.push(res.data.data.list[i]);
        }
      }
      // let dayList = res.data.data.list
      for (let i = 0; i < dayList.length; i++) {
        if (dayList[i].valueList.length != 0)
          this.dayData += Number(dayList[i].valueList[0]);
      }
    },

    // #########################################################################################
    // 设备关系中的子组件点击数据
    // propDevice(data) {
    //   // 子组件数据对象
    //   this.propDeviceInfo = data
    //   // 跳转到设备监测
    //   this.togglePage('设备监测')
    // },

    timeQuery(value, query, dudje) {
      // 计算当前月的天数
      let setDate = new Date();
      let year = setDate.getFullYear();
      let month = setDate.getMonth() + 1;
      var day = new Date(year, month, 0).getDate();
      // 减时间处理方法
      if (query === "sub") {
        if (value.name === "年") {
          value.val--;
        } else if (value.name === "月") {
          if (value.val > 1 && value.val <= 12) {
            value.val--;
          }
        } else {
          if (value.val > 1 && value.val < day) {
            value.val--;
          }
        }
      }
      // 加时间处理方法
      if (query === "add") {
        if (value.name === "年") {
          value.val++;
        } else if (value.name === "月") {
          if (value.val >= 1 && value.val < 12) {
            value.val++;
          }
        } else {
          if (value.val >= 0 && value.val < day) {
            value.val++;
          }
        }
      }
      // 查到月
      if (dudje == "month") {
        this.requestData.type = "Monthly";
        this.requestData.deviceTypeId = "DXwaterMeter"; // 设备类型  电
        this.requestData.current = this.current; // 当前页
        this.requestData.limit = this.limit;
        this.processTime(this.slotDiv1, this.requestData);
      } else {
        // 查到天
        this.requestData.type = "Daily";
        this.requestData.deviceTypeId = "DXwaterMeter"; // 设备类型  电
        this.requestData.current = this.current; // 当前页
        this.requestData.limit = this.limit;
        this.processTime(this.slotDiv, this.requestData);
      }
    },

    // 处理时间的方法
    processTime(time, params) {
      let timeArr = time.map((item) => item.val);
      for (let i = 0; i < timeArr.length; i++) {
        if (timeArr[i] < 10) {
          timeArr[i] = "0" + timeArr[i];
          timeArr[i].toString();
        }
      }

      let timeStr = "";
      timeStr = timeArr.toString().replace(/,/g, "-");
      params.startTime = timeStr;
      params.endTime = timeStr;
      this.getWaterData(params);
    },

    // 查询方法
    async getWaterData(value, date) {
      const res = await DeviceHistoryValue.getWaterMeterData(value);
      let list = res.data.data.list;
      let totalList = [];

      if (date == "year") {
        for (let i = 0; i < list.length; i++) {
          if (list[i].valueList.length !== 0) {
            let sum = 0;
            for (let j = 0; j < list[i].valueList.length; j++) {
              sum += Number(list[i].valueList[j]);
              list[i].sum = sum;
            }
          }
          if (list[i].sum) {
            this.yearData += Number(list[i].sum.toFixed(2));
          }
        }
        // this.yearData = sum.toFixed(2) + 'm³'
      } else if (date == "month") {
        for (let i = 0; i < list.length; i++) {
          if (list[i].valueList.length != 0) {
            let sum = 0;
            for (let j = 0; j < list[i].valueList.length; j++) {
              sum = list[i].valueList[j];
            }
            totalList.push(sum);
          }
        }
        let sum = 0;
        totalList.forEach((ele) => {
          sum += Number(ele);
        });
        this.monthData = sum.toFixed(2);
      } else {
        for (let i = 0; i < list.length; i++) {
          if (list[i].valueList.length != 0) {
            let sum = 0;
            for (let j = 0; j < list[i].valueList.length; j++) {
              sum = list[i].valueList[j];
            }
            totalList.push(sum);
          }
        }
        let sum = 0;
        totalList.forEach((ele) => {
          sum += Number(ele);
        });
        this.dayData = sum.toFixed(2);
      }
    },

    // 查设备数据 查询所有单位下所有设备数据  （默认 30 天）
    async getDeviceDataOfWater() {
      this.paramsObj.type = this.radio; // 日
      this.paramsObj.deviceTypeId = "DXwaterMeter"; // 设备类型  水表
      this.paramsObj.startTime = this.dataTime[0];
      this.paramsObj.endTime = this.dataTime[1];
      this.paramsObj.current = this.current; // 当前页
      this.paramsObj.limit = this.limit; // 条数
      this.paramsObj.ciIds = []; // 条数
      const res = await DeviceHistoryValue.getWaterMeterData(this.paramsObj);
      if (res) {
        this.flag = true;
      }

      this.DeviceHistoryWaterList = res.data.data.list;
      let newArr = new Array(30).fill(0);
      // 遍历公司
      for (let i = 0; i < this.DeviceHistoryWaterList.length; i++) {
        // 遍历子公司
        for (
          let j = 0;
          j < this.DeviceHistoryWaterList[i].dataList.length;
          j++
        ) {
          // 执行两次
          if (
            this.DeviceHistoryWaterList[i].dataList[j].deviceId ==
              861821066601227 ||
            this.DeviceHistoryWaterList[i].dataList[j].deviceId ==
              861821066549723
          ) {
            // 循环30天并赋值
            for (
              let k = 0;
              k < this.DeviceHistoryWaterList[i].dataList[j].valueList.length;
              k++
            ) {
              newArr[k] += parseFloat(
                this.DeviceHistoryWaterList[i].dataList[j].valueList[k]
              );
            }
          }
        }
      }
      this.ciIdList = [];
      this.valueInfoList = [];
      for (let i = 0; i < this.DeviceHistoryWaterList.length; i++) {
        const element = this.DeviceHistoryWaterList[i];
        let ciId = element.ciId;
        let dataList = element.dataList;
        this.dateListX = dataList[0].timeList;
        let arr = [];
        for (let j = 0; j < dataList.length; j++) {
          const element1 = dataList[j];
          for (let jj = 0; jj < element1.valueList.length; jj++) {
            const element2 = element1.valueList[jj];
            //如果没有这个索引的值，就往数组内添加
            if (arr[jj] == undefined) {
              arr.push(Number(element2));
            } else {
              arr[jj] = Number(Number(arr[jj]) + Number(element2)).toFixed(2);
            }
          }
        }
        let obj = {};
        obj.valueList = arr;
        let value = Number(0);
        for (const k in arr) {
          value = Number(Number(value) + Number(arr[k])).toFixed(2);
        }
        //总用水量
        obj.value = value;
        this.ciIdList.push(ciId);
        this.valueInfoList.push(obj);
      }
      // this.totalWater

      // 2023.4.13改数据为两张总表之和
      // let arr1 = []
      // this.valueInfoList.forEach(item => {
      //   this.totalWater += Number(item.value)
      //   for (let i = 0; i < item.valueList.length; i++) {
      //     const element = item.valueList[i];
      //     if (arr1[i] == undefined) {
      //       arr1.push(Number(element))
      //     } else {
      //       arr1[i] = Number(Number(arr1[i]) + Number(element)).toFixed(2)
      //     }
      //   }
      // });
      // //保留两位小数
      // this.totalWater = Number(this.totalWater).toFixed(2)
      // this.valueWaterListY = arr1

      this.valueWaterListY = newArr;
    },

    async getDeviceDataOfElect() {
      this.paramsObj.type = this.radio; // 日
      this.paramsObj.deviceTypeId = "DXElectricMeter"; // 设备类型  电
      this.paramsObj.startTime = this.dataTime[0];
      this.paramsObj.endTime = this.dataTime[1];
      this.paramsObj.current = this.current; // 当前页
      this.paramsObj.limit = this.limit; // 条数
      const res = await DeviceHistoryValue.getWaterMeterData(this.paramsObj);
      this.DeviceHistoryElectList = res.data.data.list;

      this.ciIdList1 = [];
      this.valueInfoList1 = [];
      for (let i = 0; i < this.DeviceHistoryElectList.length; i++) {
        const element = this.DeviceHistoryElectList[i];
        let ciId = element.ciId;
        let dataList = element.dataList;
        this.dateListX = dataList[0].timeList;
        let arr = [];
        for (let j = 0; j < dataList.length; j++) {
          const element1 = dataList[j];
          for (let jj = 0; jj < element1.valueList.length; jj++) {
            const element2 = element1.valueList[jj];
            //如果没有这个索引的值，就往数组内添加
            if (arr[jj] == undefined) {
              arr.push(Number(element2));
            } else {
              arr[jj] = Number(Number(arr[jj]) + Number(element2)).toFixed(2);
            }
          }
        }
        let obj = {};
        obj.valueList = arr;
        let value = Number(0);
        for (const k in arr) {
          value = Number(Number(value) + Number(arr[k])).toFixed(2);
        }
        //总用电量
        obj.value = value;
        this.ciIdList1.push(ciId);
        this.valueInfoList1.push(obj);
      }

      let arr1 = [];
      this.valueInfoList1.forEach((item) => {
        this.totalElect += Number(item.value);
        for (let i = 0; i < item.valueList.length; i++) {
          const element = item.valueList[i];
          if (arr1[i] == undefined) {
            arr1.push(Number(element));
          } else {
            arr1[i] = Number(Number(arr1[i]) + Number(element)).toFixed(2);
          }
        }
      });
      this.valueElectListY = arr1;
    },

    togglePage(value) {
      this.currentName = value;
      if (value == "数据总览") {
        this.flag1 = true;
        this.flag2 = false;
        this.flag3 = false;
        this.flag4 = false;
      } else if (value == "设备监控") {
        this.flag1 = false;
        this.flag2 = true;
        this.flag3 = false;
        this.flag4 = false;
      } else if (value == "设备监测") {
        this.flag1 = false;
        this.flag2 = false;
        this.flag3 = true;
        this.flag4 = false;
      } else {
        this.flag1 = false;
        this.flag2 = false;
        this.flag3 = false;
        this.flag4 = true;
      }
    },

    // 查询当前账号用户
    async getAccount() {
      const res = await user.loginUserInfo();
      this.users = res.data.data.userName;
      if (this.users == "九公司水电展示" || this.users == "九公司") {
        this.titleText = "秦港九公司用水管理系统";
      } else if (this.users == "水电展示" || this.users == "一公司") {
        this.titleText = "一公司能源计量采集分析系统";
      } else {
        this.titleText = "水电管理系统";
      }
      // this.getTitleText(this.users)
    },

    // 查询标题信息
    // getTitleText() {
    //   AccountManage.getShowsByUserName(user).then(res => {
    //     this.platformInfo = res.data.data.data
    //     if (this.platformInfo.platformName == undefined) {
    //       this.platformInfo.platformName = '消防物联网监控平台';
    //     }
    //   })
    // },

    // 查询所有用户left.line
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "led regular";
  src: url("../../assets/font/ds_digital/DS-DIGI.TTF");
}

.menu {
  position: absolute;
  top: 30px;
  left: 20px;

  span {
    color: rgb(42, 248, 248);
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    padding-left: 20px;
    font-size: 22px;
    font-family: "宋体";
    margin: 0 20px;
    padding: 5px 10px;
  }
}

.container {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/dptBG.jpg") no-repeat;
  background-size: 100% 100%;

  .header {
    background: url("../../assets/images/header.png") no-repeat;
    background-size: 100% 160%;

    .icon {
      position: absolute;
      top: 0;
      right: 40px;
      font-size: 25px;
      line-height: 80px;
      color: aqua;
      cursor: pointer;
    }

    .icon:hover {
      color: aquamarine;
    }

    .title {
      width: 100%;
      height: 80px;
      display: flex;

      .item:nth-child(1) {
        height: 80px;
        width: 35%;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
        color: rgb(42, 248, 248);
        letter-spacing: 4px;
        font-family: "宋体";
        cursor: pointer;
        user-select: none;
      }

      .item:nth-child(2) {
        height: 80px;
        width: 30%;
        line-height: 80px;
        font-family: "宋体";
        font-size: 36px;
        color: aqua;
        font-weight: 600;
        text-align: center;
      }

      .item:nth-child(3) {
        height: 80px;
        width: 35%;
        text-align: center;
        line-height: 80px;
        font-size: 34px;
        color: rgb(42, 248, 248);
        letter-spacing: 4px;
        font-family: "led regular";
      }
    }
  }
  .content {
    box-sizing: border-box;
    width: 100%;
    height: 90%;

    .line1 {
      box-sizing: border-box;
      padding-top: 10px;
      width: 100%;
      height: 50%;
      display: flex;

      .left {
        width: 50%;
        // border: 1px solid red;
        box-sizing: border-box;
        height: 100%;
        padding: 20px;
      }

      .right {
        box-sizing: border-box;
        width: 50%;

        .slot {
          padding: 5px;
          color: rgba(212, 239, 250, 1);
          user-select: none;

          input {
            border: none;
            outline: none;
            background: rgba(5, 110, 151, 0.3);
            color: rgba(212, 239, 250, 1);
          }

          .icon {
            padding: 0 5px;
            cursor: pointer;
            color: rgba(5, 110, 151, 0.8);
          }
        }
      }
    }

    .line2 {
      margin-top: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 50%;
      display: flex;

      .left {
        width: 100%;
        height: 100%;
      }
    }

    .overview-top {
      box-sizing: border-box;
      width: 100%;
      height: 50%;
      display: flex;

      .chart1 {
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.1);
        margin: 5px;
        border-radius: 10px;
        width: 50%;
      }

      .chart2 {
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.1);
        margin: 5px;
        border-radius: 10px;
        width: 50%;
      }
    }

    .overview-data {
      box-sizing: border-box;
      height: 50%;
      width: 100%;
      display: flex;

      .chart1 {
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.1);
        margin: 5px;
        border-radius: 10px;
        width: 50%;
      }

      .chart2 {
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.1);
        margin: 5px;
        border-radius: 10px;
        width: 50%;
      }
    }
  }
}

.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
</style>