<template>
  <div id="app">
    <div class="container">
      <div class="left">
        <div class="search">
          <el-input placeholder="输入关键字进行搜索..." prefix-icon="el-icon-search" v-model="filterText" style="margin: 0 10px 5px 0; width: 100%">
          </el-input>
          <div>
            <i class="iconfont icon-iotech24gf-tags3"></i>
            <span> 单位列表</span>
            <!-- <span style="float:right">
              <i class="iconfont icon-iotech24gf-tags3" @click="toggle"></i>
            </span> -->
          </div>
        </div>
        <!-- 公司列表 -->
        <div class="info-list">
          <el-tree :data="companyList" :props="defaultProps" highlight-current default-expand-all node-key="id" :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="getPickCompant" ref="tree">
            <span slot-scope="{ node, data }">
              <span :title="node.label">{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
      <dv-border-box-4 class="content" :color="['#135b85', '#0ce3eb']">
        <div>
          <div class="title" v-if="flag">
            <i class="iconfont icon-iotech24gf-tags3"></i>
            {{company.name}}
          </div>
          <div class="title" v-else>
            <i class="iconfont icon-iotech24gf-tags3"></i>
            周数据统计（概览）
          </div>
        </div>
        <dv-decoration-1 style="width:300px;height:10px;" :color="['#0ce3eb']" />

        <!--  -->
        <div class="banner">
          <el-col :span="6" class="rows">
            <el-date-picker v-model="dataTime" popper-class="pickerclass" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false" @change="pickTime">
            </el-date-picker>
          </el-col>
          <el-col :span="9" class="rows">
            <!-- <el-radio v-model="radio" label="Daily" border style="color:#fff;border:2px solid #008c8c;" @input="checkRadio(radio)">日用 水（电）量</el-radio>
            <el-radio v-model="radio" label="Monthly" border style="color:#fff;border:2px solid #008c8c;" @input="checkRadio(radio)">月用 水（电）量</el-radio> -->
            <el-button type="primary" plain @click="selectionWater" style="margin-left:10px">查 询</el-button>
            <!-- <el-button type="primary" plain @click="selectionWater" style="margin-left:10px">查询用水量</el-button> -->
            <!-- <el-button type="primary" plain @click="selectionElect" style="margin-left:10px">查询用电量</el-button> -->
          </el-col>

        </div>

        <div class="data-show">
          <!-- v-if="!flag" -->
          <div class="item1">
            <!-- <CountValue /> -->
            <el-table max-height="600px" :data="DeviceHistoryList" height="700px">
              <el-table-column :label="title" align="center">
                <el-table-column label="序号" type="index" width="60" align="center">
                </el-table-column>
                <el-table-column label="设备编号" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.coding}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="IMEI" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.deviceId}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="deviceName" label="设备名称" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.deviceName?scope.row.deviceName:'无'}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="总用水量" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.Wwtotal.toFixed(2)}} m3</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="平均用水量" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.Waverage.toFixed(2)}} m3</span>
                  </template>
                </el-table-column> -->
                <el-table-column label="漏失率" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.leakRate > 0? scope.row.leakRate.toFixed(2)+' %': '无'}}</span>
                  </template>
                </el-table-column>

                <el-table-column label="操作" align="center" width="130">
                  <template slot-scope="scope">
                    <el-button size="small" type="primary" plain @click="lookMore(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <!-- <el-pagination background :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" style="text-align:center" @current-change="handleCurrentChange"></el-pagination> -->
          </div>


        </div>
      </dv-border-box-4>
    </div>
    <div class="dialog" v-if="dialogVisible">
      <div class="dialog-box">
        <div class="dialog-title">
          {{dialogData.deviceName}}
          <i class="iconfont icon-iotechguanbi1" style="float:right;cursor:pointer" @click="dialogVisible = false"></i>
        </div>
        <div class="dialog-data">
          <CountValue :dataList='dialogData' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入公司结构组件

// import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import CountValue from '@/components/WaterAndElectricity/dataAnalize/CountValue'
import CompanyInfo from "@/api/managementApi/CompanyInfo";
import DeviceHistoryValue from '@/api/manageApi/DeviceHistoryValue'

export default {
  components: {
    CountValue,
  },
  data() {
    return {
      flag: false,    // 默认显示查询一周的数据
      // 公司树形结构绑定
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
      companyList: [],  // 公司列表

      dialogVisible: false,   // 弹框
      dialogData: {},

      // 查数据所用参数
      radio: 'Daily',
      deviceTypeId: 'DXwaterMeter',
      dataTime: [
        this.$moment().add(-6, 'd').format("YYYY-MM-DD HH:mm:ss"),
        this.$moment().add(0, 'd').format("YYYY-MM-DD HH:mm:ss")
      ],
      current: 1,
      limit: 1000,
      total: 1000,

      paramsObj: {},

      company: {},

      title: '《 用 水 量 》 数 据 列 表',

      // 查找公司过滤
      filterText: '',

      // 设备历史数据
      DeviceHistoryList: [],

    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getCompanyStructure()
    this.getDeviceData()

  },
  methods: {

    // radio
    checkRadio(value) {
      this.radio = value
    },

    // 选择时间
    pickTime(value) {
      // console.log(value);
      this.dataTime[0] = value[0]
      this.dataTime[1] = value[1]
      // console.log(this.dataTime);
    },

    toggle() {
      this.company.name = "周数据统计（概览）"
      this.paramsObj.ciId = ''

      this.selectionWater()
    },

    // 查看
    lookMore(row) {
      this.dialogData = row
      // console.log(this.dialogData);
      this.dialogVisible = true
    },

    // 关闭弹框
    dialogBeforeClose() {
      this.dialogVisible = false
    },



    // 查询用水量
    selectionWater() {
      this.deviceTypeId = 'DXwaterMeter'
      this.paramsObj.type = this.radio   // 日
      // this.paramsObj.deviceTypeId = 'DXwaterMeter'   // 设备类型
      this.paramsObj.startTime = this.dataTime[0]
      this.paramsObj.endTime = this.dataTime[1]
      this.paramsObj.current = this.current  // 当前页
      this.paramsObj.limit = this.limit  // 条数

      this.title = '《 用 水 量 》 数 据 列 表'
      this.getDeviceData()
    },
    // 查询用电量
    selectionElect() {
      this.deviceTypeId = 'DXElectricMeter'

      this.paramsObj.type = this.radio   // 日
      // this.paramsObj.deviceTypeId = 'SoundSignalDevice'   // 设备类型
      this.paramsObj.startTime = this.dataTime[0]
      this.paramsObj.endTime = this.dataTime[1]
      this.paramsObj.current = this.current  // 当前页
      this.paramsObj.limit = this.limit  // 条数

      // console.log(this.paramsObj);

      this.title = '《 用 电 量 》 数 据 列 表'
      this.getDeviceData()
    },

    // 选择公司过滤器
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 获取公司节点信息   (根据公司查全部设备)
    getPickCompant(value) {
      if (value) {
        this.flag = true
      }
      this.company = value
      this.paramsObj.ciId = this.company.id
      this.getDeviceData()
    },

    // 查所有公司结构
    async getCompanyStructure() {
      const { data: res } = await CompanyInfo.getAllCompanyStructure()
      // console.log(res);
      this.companyList = res.data.list;
      let com = this.companyList
    },

    // 查设备数据 查询所有单位下所有设备数据  （默认 7 天）
    async getDeviceData() {
      this.paramsObj.type = this.radio   // 日
      this.paramsObj.deviceTypeId = this.deviceTypeId   // 设备类型
      this.paramsObj.startTime = this.dataTime[0]
      this.paramsObj.endTime = this.dataTime[1]
      this.paramsObj.current = this.current  // 当前页
      this.paramsObj.limit = this.limit  // 条数
      const res = await DeviceHistoryValue.getWaterMeterData(this.paramsObj)
      console.log(res);
      this.total = res.data.data.total

      this.DeviceHistoryList = res.data.data.list
      this.DeviceHistoryList.sort((a,b)=>(a.number-b.number))
      console.log(this.DeviceHistoryList);
      for (let i = 0; i < this.DeviceHistoryList.length; i++) {
        let Wwtotal = 0   // 总用水量
        let lowerWtotal = 0   // 下级设备总用水量
        for (let j = 0; j < this.DeviceHistoryList[i].valueList.length; j++) {
          Wwtotal += Number(this.DeviceHistoryList[i].valueList[j])
        }
        // 如果有下级设备，计算下级设备总用水量
        if (this.DeviceHistoryList[i].leakage) {
          for (const k in this.DeviceHistoryList[i].leakage) {
            for (let n = 0; n < this.DeviceHistoryList[i].leakage[k].length; n++) {
              // console.log(eval(this.DeviceHistoryList[i].leakage[k][n].valueList.join('+')));
              lowerWtotal += eval(this.DeviceHistoryList[i].leakage[k][n].valueList.join('+'))
            }
          }
        }
        // console.log('***********');
        // 总用水量
        this.DeviceHistoryList[i].Wwtotal = Wwtotal
        // 平均用水量
        this.DeviceHistoryList[i].Waverage = (Wwtotal / this.DeviceHistoryList[i].timeList.length)
        // 漏水率
        if(this.DeviceHistoryList[i].leakage){
          this.DeviceHistoryList[i].leakRate = ((Wwtotal - lowerWtotal) / Wwtotal) * 100
        }
      }
      // console.log(this.DeviceHistoryList);
    },

    // 分页
    // handleCurrentChange() {
    //   this.getDeviceData()
    // }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/uiCss.scss";

.container {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 90vh;
  display: flex;

  .left {
    box-sizing: border-box;
    width: 15%;
    height: 100%;
    padding: 10px 5px;
    border-radius: 5px;

    .search {
      height: 11%;
      width: 100%;
      div {
        font-size: 20px;
        font-family: "宋体";
        font-weight: 600;
        color: #0ce3eb;
        margin: 10px;
      }
    }
    .info-list {
      margin-top: 1%;
      height: 88%;
      width: 100%;
      overflow: scroll;

      /deep/.el-tree-node__content {
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        background: none;
      }
      .el-tree {
        color: #80ecf0;
        background: none;
      }
      /deep/.el-tree-node__content:hover {
        //   background: rgb(48, 79, 145);
        background: #fff;
        color: #035e61;
        font-weight: 600;
        border-radius: 3px;
      }
      /deep/.el-tree-node__content:hover {
        background: rgba(113, 213, 238, 0.5);
      }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .content {
    box-sizing: border-box;
    // border: 1px solid rgb(215, 180, 248);
    padding: 20px 0 0 30px;
    width: 85%;
    height: 100%;
    // margin-left: 10px;
    // background: rgba(0, 0, 0, 0.5);

    .title {
      color: #0ce3eb;
      height: 45px;
      line-height: 45px;
      font-size: 22px;
      font-weight: 600;
      font-family: "宋体";
    }
    .banner {
      box-sizing: border-box;
      padding-top: 15px;
      width: 100%;
      height: 8%;

      .el-radio {
        margin: 0 0px 0 10px;
      }
    }

    .data-show {
      position: relative;
      width: 100%;
      height: 85%;

      .item1 {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.dialog {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  background: rgba(0, 0, 0, 0.4);

  .dialog-box {
    box-sizing: border-box;
    width: 55%;
    height: 650px;
    background: rgb(75, 96, 128);
    border-radius: 5px;
    margin: 150px auto 0;
    padding: 20px;

    .dialog-title {
      font-size: 22px;
      font-family: "宋体";
      font-weight: 600;
      letter-spacing: 2px;
      color: #0ce3eb;
    }
    .dialog-data {
      box-sizing: border-box;
      width: 100%;
      height: 95%;
      // border: 1px solid red;
      padding: 10px;
      margin: 10px 0;
    }
  }
}
/deep/.el-table thead.is-group th.el-table__cell {
  background: rgba(53, 110, 148, 0.4);
  border: none;
}

/deep/.el-table__body tr:hover>td {
  background-color:rgba($color: #007a91, $alpha: 0.5) !important;
}

/deep/.el-table__row:hover {
  color: #72d4dc;
  }


</style>